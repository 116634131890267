import React from 'react';
import persona1 from '../../assets/images/home/section_11/persona_1.png';
import persona2 from '../../assets/images/home/section_11/persona_2.png';
import persona3 from '../../assets/images/home/section_11/persona_3.png';
import Animated from 'components/generals/AnimatedComponent';

export default function Home__section__11() {
    return (
        <>
            <main
                className="nv__section nv__section--no-margin  nv__hp__section__11"
                id="community"
            >
                <section className="nv__section__container">
                    <Animated animation={'animate__fadeInUp animate__delay-1s'}>
                        <h1>Nuestros Partners</h1>
                    </Animated>
                    <Animated animation={'animate__fadeInDown animate__delay-1s'}>
                        <p>
                            Nos enorgullece contar con un equipo de expertos en The Spots Of The
                            World de clase mundial, que colaboran para hacer realidad nuestros
                            proyectos excepcionales.
                        </p>
                    </Animated>

                    <div className="partners">
                        <div className="partners__items">
                            <Animated animation={'animate__fadeInDown animate__delay-1s'}>
                                <div className="partners__items__content">
                                    <div className="partners__items__photo">
                                        <img src={persona1} alt="Chef José Manuel Baños" />
                                    </div>
                                    <h4 className="partners__items__title">
                                        Chef José Manuel Baños
                                    </h4>
                                    <p className="partners__items__position">Socio operativo</p>
                                </div>
                            </Animated>
                        </div>
                        <div className="partners__items">
                            <Animated animation={'animate__fadeInUp animate__delay-2s'}>
                                <div className="partners__items__content">
                                    <div className="partners__items__photo">
                                        <img src={persona2} alt="Arq. Eduardo Cadaval" />
                                    </div>

                                    <h4 className="partners__items__title">Arq. Eduardo Cadaval</h4>
                                    <p className="partners__items__position">
                                        Diseñador arquitectónico
                                    </p>
                                </div>
                            </Animated>
                        </div>
                        <div className="partners__items">
                            <Animated animation={'animate__fadeInDown animate__delay-3s'}>
                                <div className="partners__items__content">
                                    <div className="partners__items__photo">
                                        <img src={persona3} alt="Arq. Ariel Rojo" />
                                    </div>
                                    <h4 className="partners__items__title">Arq. Eduardo Cadaval</h4>
                                    <p className="partners__items__position">
                                        Diseñador proyectista de mobiliario y equipamiento.
                                    </p>
                                </div>
                            </Animated>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}
