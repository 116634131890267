import React from 'react';

import logo from '../../assets/images/home/section_5/logo_ssd.png';
import church from '../../assets/images/home/section_5/church.png';
import ActionButton from 'components/ui/buttons/action-button';
import texture1 from '../../assets/images/home/section_6/texture_1.png';
import edificio from '../../assets/images/home/section_6/edificio.png';
import Animated from 'components/generals/AnimatedComponent';

export default function Home__section__6() {
    return (
        <>
            <main className="nv__section nv__section--no-margin  nv__hp__section__6">
                <div className="info__element">
                    <div className="info__element__texture">
                        <img src={texture1} alt="textura SSD" />
                    </div>
                    <section className="nv__section__container">
                        <Animated animation={'animate__fadeInLeft animate__delay-1s'}>
                            <h1 className="info__element__title">Transparencia y confianza</h1>
                        </Animated>

                        <Animated animation={'animate__fadeInRight animate__delay-1s'}>
                            <p className="info__element__description">
                                Creemos firmemente en la transparencia y la honestidad en cada una
                                de nuestras transacciones reflejando el respeto por nuestros socios.
                            </p>
                        </Animated>
                    </section>
                </div>

                <div className="info__element">
                    <section className="nv__section__container">
                        <Animated animation={'animate__fadeInLeft animate__delay-2s'}>
                            <h1 className="info__element__title">Asesoramiento personalizado</h1>
                        </Animated>

                        <Animated animation={'animate__fadeInRight animate__delay-2s'}>
                            <p className="info__element__description">
                                Nuestros asesores expertos están aquí para guiarte durante el
                                proceso y responder a todas tus preguntas.
                            </p>
                        </Animated>
                    </section>
                    <div className="info__element__texture">
                        <img src={texture1} alt="textura SSD" />
                    </div>
                </div>

                <div className="info__element">
                    <div className="info__element__texture">
                        <img src={texture1} alt="textura SSD" />
                    </div>
                    <section className="nv__section__container">
                        <Animated animation={'animate__fadeInLeft animate__delay-3s'}>
                            <h1 className="info__element__title">Rendimientos competitivos</h1>
                        </Animated>

                        <Animated animation={'animate__fadeInRight animate__delay-3s'}>
                            <p className="info__element__description">
                                Ofrecemos rendimientos superiores al los del mercado.
                            </p>
                        </Animated>
                    </section>
                </div>

                <div className="info__element">
                    <section className="nv__section__container">
                        <Animated animation={'animate__fadeInLeft animate__delay-4s'}>
                            <h1 className="info__element__title">Cadena de expansión</h1>
                        </Animated>
                        <Animated animation={'animate__fadeInRight animate__delay-4s'}>
                            <p className="info__element__description">
                                The Spots Of The World planea crear 10 spots emblemáticos <br />
                                al rededor del país.
                            </p>
                        </Animated>
                    </section>
                    <div className="info__element__building">
                        <Animated animation={'animate__fadeIn animate__delay-4s'}>
                            <img src={edificio} alt="Edificio SSD" />
                        </Animated>
                    </div>
                </div>
                <div className="main__texture"></div>
            </main>
        </>
    );
}
