import React from 'react';
import footerLogo from 'assets/images/logos/ssd-logo-cream.png';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaInstagram, FaTiktok, FaWhatsapp } from 'react-icons/fa';
import config from 'config';
const menuItems = [
    {
        name: 'Feature',
        url: '#',
    },
    {
        name: 'Pricing',
        url: '#',
    },

    {
        name: 'Community',
        url: '#',
    },
];

const menuSocial = [
    {
        name: 'Facebook',
        icon: <FaFacebookF />,
        url: 'https://www.facebook.com/thespotsoftheworld?rdid=qgsTe0sNslupxKzK&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2Fxz9XfnNwhgUvqJr1%2F',
    },
    {
        name: 'Instagram',
        icon: <FaInstagram />,
        url: 'https://www.instagram.com/thespotsoftheworld?igsh=MWtiMjB0eDhrOTE4bQ%3D%3D',
    },
    {
        icon: <FaTiktok />,
        url: 'https://www.tiktok.com/@spotsantodomingo?_t=8qQzTPyknYM&_r=1',
    },
    {
        name: 'Whatsapp',
        icon: <FaWhatsapp />,
        url: 'https://wa.me/5625083441',
    },
];
export default function MainFooter() {
    const { t } = useTranslation();
    const currentYear = new Date().getFullYear();

    return (
        <footer className="nv__footer">
            <section className="nv__section nv__section--no-margin">
                <section className="nv__section__container">
                    <div className="nv__footer__block">
                        <div className="nv__footer__logo">
                            <img src={footerLogo} alt="logo" />
                        </div>
                        <p>© {currentYear} Spot Santo Domingo. Todos los Derechos Reservados</p>
                    </div>
                    <div className="nv__footer__block nv__footer__block--menu">
                        <ul className="nv__footer__menu">
                            {menuItems.map((item) => {
                                return (
                                    <li key={item.name}>
                                        <Link className="nv__link__menu" to={item.url}>
                                            {' '}
                                            {t(item.name)}
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div className="nv__footer__block">
                        <ul className="nv__footer__social">
                            {menuSocial.map((item) => {
                                return (
                                    <li key={item.name}>
                                        <Link to={item.url} target="_blank">
                                            {' '}
                                            {item.icon}
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </section>
            </section>

            <div className="nv__footer__politycs">
                <Link
                    target="_blank"
                    to={`${config.SITE_URL}/assets/documentos/terminos_y_condiciones_de_uso_del_sitio_web.pdf`}
                >
                    Política de privacidad
                </Link>
                <Link
                    target="_blank"
                    to={`${config.SITE_URL}/assets/documentos/terminos_y_condiciones_de_uso_del_sitio_web.pdf`}
                >
                    Términos
                </Link>
                <Link to="#">Cookies</Link>
            </div>
        </footer>
    );
}
