import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import number1 from '../../assets/images/home/section_7/numeros-1.png';
import number2 from '../../assets/images/home/section_7/numeros-2.png';
import number3 from '../../assets/images/home/section_7/numeros-3.png';
import number4 from '../../assets/images/home/section_7/numeros-4.png';
import Animated from 'components/generals/AnimatedComponent';

export default function Home__section__7() {
    const location = useLocation();

    useEffect(() => {
        const hash = location.hash;
        if (hash) {
            setTimeout(() => {
                const element = document.querySelector(hash);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }, 0);
        }
    }, [location]);

    return (
        <>
            <main className="nv__section nv__section--no-margin  nv__hp__section__7" id="feature">
                <section className="nv__section__container">
                    <Animated animation={'animate__fadeInDown animate__delay-1s'}>
                        <h1>Elige entre nuestros diferentes tickets</h1>
                    </Animated>
                    <Animated animation={'animate__fadeInDown animate__delay-2s'}>
                        <h2>Diseñados para adaptarse a cada socio</h2>
                    </Animated>

                    <div className="tickets">
                        <div className="tickets__items">
                            <Animated animation={'animate__fadeIn animate__delay-2s'}>
                                <img src={number1} alt="Número 1" />
                            </Animated>
                        </div>
                        <div className="tickets__items">
                            <Animated animation={'animate__fadeIn animate__delay-3s'}>
                                <img src={number2} alt="Número 2" />
                            </Animated>
                        </div>
                        <div className="tickets__items">
                            <Animated animation={'animate__fadeIn animate__delay-4s'}>
                                <img src={number3} alt="Número 3" />
                            </Animated>
                        </div>
                        <div className="tickets__items">
                            <Animated animation={'animate__fadeIn animate__delay-5s'}>
                                <img src={number4} alt="Número 4" />
                            </Animated>
                        </div>
                    </div>
                </section>

                <div className="cirle cirle--1"></div>
                <div className="cirle cirle--2"></div>
                <div className="cirle cirle--3"></div>
                <div className="cirle cirle--4"></div>
            </main>
        </>
    );
}
