import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
const menuItems = [
    {
        name: 'Feature',
        url: '/#feature',
    },
    {
        name: 'Pricing',
        url: '/#pricing',
    },

    {
        name: 'Community',
        url: '/#community',
    },
];

export default function Menu() {
    const { t } = useTranslation();

    return (
        <>
            <section>
                <ul className="nv__header__menu  nv__header__menu--web">
                    {menuItems.map((item) => {
                        return (
                            <li key={item.name}>
                                <Link className="nv__link__menu" to={item.url}>
                                    {' '}
                                    {t(item.name)}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </section>
        </>
    );
}
